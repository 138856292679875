import './style.css'
import './App.css';
// import AlertMsg from './AlertMessage/AlertMsg';
import Home from './components/Home';
// import '../node_modules/bootstrap5/src/css/bootstrap.min.css'
import { BrowserRouter as Router, Routes, Route,NavLink } from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import Certificates from './components/Certificates';
import Products from './components/products/Products';
import Error from './components/Error';
import AutoProducts from './components/products/automative/AutoProducts';
import GrsProducts from './components/products/grease/GrsProducts';
import MrnProducts from './components/products/marine/MrnProducts';
import SPProducts from './components/products/specialityProducts/SPProducts';
import AutoProductDetail from './components/products/automative/AutoProductDetail';
import AlertMsg from './AlertMessage/AlertMsg';

function App() {

  const noUnderline = {
    textDecoration : "none"
  }
  return (
    <div className="App">

      <AlertMsg />

       
      {/* <Router>

        <header>
          <nav>
            <ul>
              <NavLink to="/" className={ ({ isActive }) => isActive?  'active-navlink': ''} style={noUnderline}><li>Home</li></NavLink>
              <NavLink to="/about" className={ ({ isActive }) => isActive? 'active-navlink': ''}  style={noUnderline}><li>About</li></NavLink>
              <NavLink to="/contact" className={ ({ isActive }) => isActive?  'active-navlink': ''} style={noUnderline}><li>Contact</li></NavLink>
              <NavLink to="/cer" className={ ({ isActive }) => isActive?  'active-navlink': ''} style={noUnderline}><li>Certificates</li></NavLink>
              <NavLink to="/products" className={ ({ isActive }) => isActive? 'active-navlink': ''} style={noUnderline}><li>Products</li></NavLink>
            </ul>
          </nav>
        </header>

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/certificate' element={<Certificates />} />
          <Route path='/products' element={<Products />} />
          <Route path='/products/automative' element={<AutoProducts />}/>
          <Route path='/products/grease' element={<GrsProducts />}/>
          <Route path='/products/marine' element={<MrnProducts />}/>
          <Route path='/products/industry' element={<SPProducts />}/>

          <Route path='/products/automative/:autoProductId' element={<AutoProductDetail />}/>
          <Route path='/products/marine/:autoProductId' element={<AutoProductDetail />}/>
          <Route path='/products/industry/:autoProductId' element={<AutoProductDetail />}/>
          <Route path='/products/grease/:autoProductId' element={<AutoProductDetail />}/>
        
          <Route path='*' element={<Error />} />
        </Routes>
      </Router>  */}
    </div>
  );
}

export default App;
