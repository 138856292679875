import React from 'react';

function AlertMsg() {
    return (
        <div className='alert'>
            <div className="content">
                <h1>SKYTECH LUBRICANTS TRADING L.L.C.</h1>
                <p>This website is currently under development</p>
                <p>But you can visit our social media platforms   👇</p>
            </div>

            <div className="links">
                <a href="https://www.facebook.com/skytechlubricants" target='_blank'><i class="fa-brands fa-facebook-f facebook"></i></a>
                <a href="https://www.linkedin.com/in/muhammad-siraj-ul-haq-05497a28b/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-linkedin-in linkedIn"></i></a>
                <a href="mailto:skytechlube@gmail.com" target="_blank" rel="noopener noreferrer"><i class="fa-regular fa-envelope email"></i></a>
            </div>
        </div>
    );
}

export default AlertMsg;